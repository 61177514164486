html,
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@media (min-width: 500px) {
  .container {
    max-width: 100%;
  }
}

.col-image {
  padding: 0 !important;
}

.row-margin {
  margin-bottom: 4%;
}

.button-save-margin {
  margin-bottom: 10%;
}

.margin-form {
  margin-top: 2%;
}

.margin-portrait {
  margin-bottom: 10%;
}

.error {
  display: none;
  color: red;
  font-weight: bold;
}

@media screen and (orientation: portrait) {
  .row-content {
    position: relative;
    top: 30%;
  }

  .row-content-password {
    position: relative;
    top: 10%;
    margin-right: 0px;
  }

  .circle-mobile {
    position: relative;
    width: 100%;
    height: 23%;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 100;

    .modal-error {
      padding: 3vh;
      position: relative;
      background: white;
      width: 70vw;
      height: 25vh;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 5px;
      text-align: center;
      color: black;
      font-family: "Montserrat";
      -webkit-box-shadow: 0 8px 6px -6px black;
      -moz-box-shadow: 0 8px 6px -6px black;
      box-shadow: 0 8px 6px -6px black;
    }

    .modal-success {
      padding: 3vh;
      position: relative;
      background: white;
      width: 70vw;
      height: 40vh;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 5px;
      text-align: center;
      color: black;
      font-family: "Montserrat";
      -webkit-box-shadow: 0 8px 6px -6px black;
      -moz-box-shadow: 0 8px 6px -6px black;
      box-shadow: 0 8px 6px -6px black;
    }

    .modalPasswordTitle {
      font-size: 15px;
      font-weight: bold;
    }

    .modalPasswordSubtitle {
      font-size: 15px;
      margin: 10px;
    }

    .buttonCloseModalError {
      font-size: 20px;
      color: #00c1ad;
    }

    .buttonCloseModalSuccess {
      font-size: 20px;
      font-weight: bold;
    }
  }
}

@media screen and (orientation: landscape) {
  .row-content {
    position: fixed;
    top: 12%;
  }

  .row-content-password {
    position: absolute;
    top: 10%;
    margin-right: 0px;
  }

  .circle-mobile {
    position: relative;
    width: 100%;
    height: 23%;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 100;

    .modal-error {
      padding: 4.5vh;
      position: absolute;
      background: white;
      width: 30vw;
      height: 30vh;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 5px;
      text-align: center;
      color: black;
      font-family: "Montserrat";
      -webkit-box-shadow: 0 8px 6px -6px black;
      -moz-box-shadow: 0 8px 6px -6px black;
      box-shadow: 0 8px 6px -6px black;
    }

    .modal-success {
      padding: 8vh;
      position: relative;
      background: white;
      width: 60vw;
      height: 45vh;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 5px;
      text-align: center;
      color: black;
      font-family: "Montserrat";
      -webkit-box-shadow: 0 8px 6px -6px black;
      -moz-box-shadow: 0 8px 6px -6px black;
      box-shadow: 0 8px 6px -6px black;
    }

    .modalPasswordTitle {
      font-size: 3vh;
      font-weight: bold;
    }

    .modalPasswordSubtitle {
      font-size: 2vh;
      margin: 10px;
    }

    .buttonCloseModalError {
      font-size: 3.5vh;
      color: #00c1ad;
    }

    .buttonCloseModalSuccess {
      font-size: 3.5vh;
      font-weight: bold;
    }
  }
}

.form-row {
  height: 100vh;
  margin-left: -15px;
}



.display-block {
  display: block;
}

.display-none {
  display: none;
}